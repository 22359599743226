import React from "react";
import Exploration from "../components/Exploration";

function Home(){
    return (
    <div>
        <Exploration />
    </div>
    );
}

export default Home;