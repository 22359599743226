import React from "react";
import Pattern from "../components/Pattern";

function AnswerPattern() {
  return (
    <div>
        <Pattern />
    </div>
  );
}

export default AnswerPattern;
