import React from 'react';
import '../custom/styles/Loading.css';

const Loading = () => {
    return (
        <div className="loading-body-container">
            <div className="loading-textContainer">
                <h1>Loading...</h1>
                <div className="loader"></div>
            </div>
        </div>
    );
}

export default Loading;
