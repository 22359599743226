import { LdsFooter, LdsLink } from '@elilillyco/ux-lds-react';
import '@elilillyco/ux-lds-react/src/css/index.css';
import '../custom/styles/Footer.css';

const Footer = ({ initLoad, notFound }) => {
    return (
      <LdsFooter className="footer">
          <LdsFooter.GlobalLinks>
            <p>Copyright © 2024 Eli Lilly and Company and its affiliates. All rights reserved.</p>
            <LdsLink href="https://now.lilly.com/page/global-working-with-third-parties">WwTP LillyNow</LdsLink>
            <LdsLink href="https://collab.lilly.com/sites/WwTPPerformanceSupport">WwTP Performance Support</LdsLink>
          </LdsFooter.GlobalLinks>
      </LdsFooter>
    )
}

export default Footer;