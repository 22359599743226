import { useState, useMemo } from 'react';

export const usePagination = (data = [], itemsPerPage) => { // Set a default empty array for data
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total pages with memoization to avoid recalculating unnecessarily
  const totalPages = useMemo(() => Math.ceil(data.length / itemsPerPage), [data, itemsPerPage]);

  // Create paginated data using memoization
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return data.slice(startIndex, startIndex + itemsPerPage);
  }, [data, currentPage, itemsPerPage]);

  return { currentPage, setCurrentPage, paginatedData, totalPages };
};

