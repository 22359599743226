import { useState, useEffect } from 'react';
import { getAnswerPattern } from '../utils/api'; // Import the API function

export const useAnswerPattern = (country, areaCode, riskCategory, riskLevel) => {
  const [answerPattern, setAnswerPattern] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Reset function to clear answer pattern and error
  const reset = () => {
    setAnswerPattern([]);
    setError(null);
  };

  useEffect(() => {
    // Proceed only if country or areaCode and riskCategory, riskLevel are provided
    if ((country || areaCode) && riskCategory && riskLevel) {
      setLoading(true);
      setError(null);
      
      const fetchAnswerPattern = async () => {
        try {
          // Fetch the answer pattern from the backend
          const result = await getAnswerPattern(country || '', areaCode || '', riskCategory, riskLevel); 
          console.log("Received result from backend:", result); // Debug log

          // Check if there's no valid combination based on predefined filter
          if (result.pattern.length === 0) {
            setError("No valid data available for the selected filters.");
            setAnswerPattern([]);
          } else {
            setAnswerPattern(result.pattern || []);
            setError(null); // Clear any previous error
          }
        } catch (err) {
          console.error("Error fetching answer pattern:", err);
          setError('Failed to fetch answer pattern');
          setAnswerPattern([]);
        } finally {
          setLoading(false);
        }
      };

      fetchAnswerPattern();
    } else {
      // Reset if required conditions are not met
      reset();
    }
  }, [country, areaCode, riskCategory, riskLevel]);

  return { answerPattern, loading, error, reset };
};
