import { LdsButton } from '@elilillyco/ux-lds-react';
import '../custom/styles/AccessDenied.css';

const AccessDenied = () => {
    return (
        <div className="body-container">
            <div className="textContainer">
                <h1>Access Denied</h1>
                <h2>User is not authorized to access the WwTP Commodity Code Risk application.</h2>
                <LdsButton>
                    <a href="mailto:de_los_angeles_chyna@lilly.com" className="contact-btn">Contact Application Owner</a>
                </LdsButton>
            </div>
        </div>
    )
}

export default AccessDenied;