import'@elilillyco/ux-lds-react';
import '@elilillyco/ux-lds-react/src/css/index.css';

import Home from "../pages/Home"
import AnswerPattern from "../pages/AnswerPattern"
import RiskIndicators from "../pages/RiskIndicators"

const Content = ({ initLoad, notFound, cookieFile, setCookieFile, initloading, validPersona, setValidPersona, setpersonaAccess, personaAccess,subcontenttab }) => {
  return (
      (subcontenttab && subcontenttab === "Home") ? <Home /> : 
      (subcontenttab && subcontenttab === "AnswerPattern") ? <AnswerPattern /> : 
      (subcontenttab && subcontenttab === "RiskIndicators") ? <RiskIndicators /> : <></>    
  )  
}
export default Content;