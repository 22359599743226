import React from "react";
import Risk from "../components/Risk";

function RiskIndicators() {
  return (
    <div>
        <Risk />
    </div>
  );
}

export default RiskIndicators;
