import React, {useEffect, useState} from 'react';
import { LdsBackToTop, LdsPagination, LdsBadge, LdsCard, LdsTabPanel, LdsTabs} from '@elilillyco/ux-lds-react';
import '../custom/styles/Exploration.css';

import { useFetchFile } from '../hooks/useFetchFile';
import { useDataFilter } from '../hooks/useDataFilter';
import { usePagination } from '../hooks/usePagination';
import { useRowCount } from '../hooks/useRowCount';
import { useColumnData } from '../hooks/useColumnData';

import DataTable from './table/DataTable';
import SelectDrop from './select/SelectDrop';
import Chart from './charts/Chart';
import Loading from './Loading';

function Exploration() {
  // Fetch file data only once
  const { data, fileName, columns, loading, error } = useFetchFile('data/cleaned/cleaned_');
  // Filter data once fetched
  const { filters, filteredData, handleFilterChange, clearSelections, setData, uniqueLoading, uniqueError } = useDataFilter();
  // Paginate filtered data
  const { currentPage, setCurrentPage, paginatedData, totalPages } = usePagination(filteredData, 8);
  // Extract selected filter values
  const selectedCountry = filters.find(filter => filter.name === 'Engagement Service Location')?.selectedValue || '';
  const selectedAreaCode = filters.find(filter => filter.name === 'Commodity Codes')?.selectedValue || '';

  // Fetch row counts based on filtered data
  const { rowCount, rowCountLevel1, loading: rowCountLoading, error: rowCountError } = useRowCount(selectedCountry, selectedAreaCode);
  // Fetch column data and modes for charts based on selected filters
  const { columnData, modes, loading: columnDataLoading, error: columnDataError } = useColumnData(selectedCountry, selectedAreaCode);

  // Calculate the percentage of rowCountLevel1 relative to rowCount
  const level1Percentage = rowCount > 0 ? ((rowCountLevel1 / rowCount) * 100).toFixed(2) : 0;

  const [currentTab, setCurrentTab] = useState(0);

  // Set the fetched data into the filter hook's state
  useEffect(() => {
    // Add a log to help with debugging
    console.log('Exploration component useEffect - data changed');

    // Check if the data is different before updating to avoid infinite loop
    if (data.length && filteredData !== data) {
      console.log('Setting data in Exploration component');
      setData(data);
    }
  }, [data, setData, filteredData]);

  // Handle all loading and error states in one place
  if (loading) {
    return <h3><Loading /></h3>;
  }

  if (error || uniqueError || rowCountError || columnDataError) {
    return <p className="error-message">{error || uniqueError || rowCountError || columnDataError}</p>;
  }
  return (
    <div>
      <div className="data-display">
        <div className="table-wrapper" tabIndex="0" role="group" aria-labelledby="tableCaption3">
          <div className="pagination-controls">
              <LdsBadge outlined>{fileName}</LdsBadge>
              <LdsPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                useButtons={true}
              />
            </div>
            <DataTable columns={columns} data={paginatedData} />
            <br/>
            <div className="filters">
            {filters.map((filter, index) => (
              <SelectDrop
                key={index}
                label={filter.label}
                options={filter.options}
                selectedValue={filter.selectedValue}
                onChange={(e) => handleFilterChange(filter.name, e.target.value)}
                clearSelection={() => clearSelections(filter.name)}
              />
            ))}
          </div>
          </div>
          <br />
          <div className='dashboard-wrapper'>
            <div className="dashboard-header">
              <h2>{selectedCountry || 'Select Country'}, {selectedAreaCode || 'Code'}</h2>
            </div>


          <div className="row">
            <div className="col col-md-6">
              <LdsCard>
                <LdsCard.Body>
                  <strong>{rowCount}</strong>
                  <p>Total Engagements</p>
                </LdsCard.Body>
              </LdsCard>
            </div>
            <div className="col col-md-6">
              <LdsCard>
                <LdsCard.Body>
                  <strong>{rowCountLevel1} ({level1Percentage}%)</strong>
                  <p>Total Engagements (All Level 1's)</p>
                </LdsCard.Body>
              </LdsCard>
            </div>
          </div>
          <br />
          <div className="tabs-1">
                <LdsTabs
                    id="tabGroup1"
                    activeTab={currentTab}
                    onChange={(newIndex) => setCurrentTab(newIndex)}
                    tabLabels={[
                    { tabId: 0, label: 'Lead Question Distribution' },
                    { tabId: 1, label: 'Inherent Risk Level Distribution' },
                    { tabId: 2, label: 'CI & PI Processed' },
                    ]}
                >
                    <LdsTabPanel tabId={0} activeTab={currentTab}>
                        <div className="row">
                            <div className="col col-md-4">
                                <LdsCard>
                                    <LdsCard.Body>
                                        <Chart columnData={columnData} columnName="AC LIRQ" />
                                        <strong>Anti-Corruption Lead Question</strong>
                                    </LdsCard.Body>
                                </LdsCard>
                            </div>
                            
                            <div className="col col-md-4">
                                <LdsCard>
                                    <LdsCard.Body>
                                        <Chart columnData={columnData} columnName="Privacy LIRQ" />
                                        <strong>Privacy Lead Question</strong>
                                    </LdsCard.Body>
                                </LdsCard>
                            </div>
                                
                            <div className="col col-md-4">
                                <LdsCard>
                                    <LdsCard.Body>
                                        <Chart columnData={columnData} columnName="IS LIRQ" />
                                        <strong>Information Security Lead Question</strong>
                                    </LdsCard.Body>
                                </LdsCard>
                            </div>
                            
                            <div className="col col-md-4">
                                <LdsCard>
                                    <LdsCard.Body>
                                        <Chart columnData={columnData} columnName="AW LIRQ" />
                                        <strong>Animal Welfare Lead Question</strong>
                                    </LdsCard.Body>
                                </LdsCard>
                            </div>
                            
                            <div className="col col-md-4">
                                <LdsCard>
                                    <LdsCard.Body>
                                        <Chart columnData={columnData} columnName="BC LIRQ" />
                                        <strong>Business Continuity Lead Question</strong>
                                    </LdsCard.Body>
                                </LdsCard>
                            </div>
                        </div>
                    </LdsTabPanel>

                    <LdsTabPanel tabId={1} activeTab={currentTab}>
                        <div className="row">
                            <div className="col col-md-4">
                                <LdsCard>
                                    <LdsCard.Body>
                                        <Chart columnData={columnData} columnName="AC Risk Level" />
                                        <strong>Anti-Corruption Risk Level</strong>
                                    </LdsCard.Body>
                                </LdsCard>
                            </div>

                            <div className="col col-md-4">
                                <LdsCard>
                                    <LdsCard.Body>
                                        <Chart columnData={columnData} columnName="Privacy Risk Level" />
                                        <strong>Privacy Risk Level</strong>
                                    </LdsCard.Body>
                                </LdsCard>
                            </div>
                            <div className="col col-md-4">
                                <LdsCard>
                                    <LdsCard.Body>
                                        <Chart columnData={columnData} columnName="IS Risk Level" />
                                        <strong>Information Security Risk Level</strong>
                                    </LdsCard.Body>
                                </LdsCard>
                            </div>
                            <div className="col col-md-4">
                                <LdsCard>
                                    <LdsCard.Body>
                                        <Chart columnData={columnData} columnName="AW Risk Level" />
                                        <strong>Animal Welfare Risk Level</strong>
                                    </LdsCard.Body>
                                </LdsCard>
                            </div>
                            <br />
                            <div className="col col-md-4">
                                <LdsCard>
                                    <LdsCard.Body>
                                        <Chart columnData={columnData} columnName="BC Risk Level" />
                                        <strong>Business Continuity Risk Level</strong>
                                    </LdsCard.Body>
                                </LdsCard>
                            </div>
                        </div>
                    </LdsTabPanel>

                    <LdsTabPanel tabId={2} activeTab={currentTab}>
                        <div className="row">
                            <div className="col col-md-6">
                                <LdsCard>
                                    <LdsCard.Body>
                                        <p>Most Common CI Processed: <strong>{modes.CI}</strong></p>
                                        <Chart columnData={columnData} columnName="IS 1.4" excludeValue="IS LQ - No" />
                                        <strong>Confidential Information Processed</strong>
                                    </LdsCard.Body>
                                </LdsCard>
                            </div>

                            <div className="col col-md-6">
                                <LdsCard>
                                    <LdsCard.Body>
                                    <p> Most Common CI Processed: <strong>{modes.PI}</strong></p>
                                        <Chart columnData={columnData} columnName="Privacy 2.2" excludeValue="Privacy LQ - No" />
                                        <strong>Personal Information Processed</strong>
                                    </LdsCard.Body>
                                </LdsCard>
                            </div>
                        </div>
                    </LdsTabPanel>
                </LdsTabs>
            </div>
        </div>
        <LdsBackToTop hideText />
      </div>
    </div>
  );
}

export default Exploration;