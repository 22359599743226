import { useState, useEffect } from 'react';
import { getUniqueValues } from '../utils/api';

export const useUniqueValues = () => {
  const [uniqueCountries, setUniqueCountries] = useState([]);
  const [uniqueAreaCodes, setUniqueAreaCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    async function fetchUniqueValues() {
      try {
        setLoading(true);
        setError(null);

        // Fetch unique values (this uses cached data from the backend if available)
        const result = await getUniqueValues();

        if (isMounted) {
          setUniqueCountries(result.countries);
          setUniqueAreaCodes(result.area_codes);
        }
      } catch (error) {
        if (isMounted) {
          setError(error.message);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    fetchUniqueValues();

    return () => {
      isMounted = false; // Cleanup to prevent state updates if component unmounts
    };
  }, []);

  return { uniqueCountries, uniqueAreaCodes, loading, error };
};