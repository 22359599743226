import { useState, useEffect } from 'react';
import { fetchFile } from '../utils/api';

export const useFetchFile = (prefix = 'data/', refresh = false) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      try {
        setLoading(true);
        setError(null);

        // Fetch the file data
        // const result = await fetchFile(null, prefix, refresh,1000,0);
        await fetchFile(null, prefix, refresh,500,0,0,{},function (result) {
          if (isMounted) {
            // Debug: Log the received result
            console.log('Fetched file result:', result);
            
            // Set data, columns, and fileName if available
            setColumns(result.columns || []);
            setData(result.data || []);
            setFileName(result.file_name || '');
  
            // Debug: Log the state values after setting
            console.log('Columns:', result.columns);
            console.log('Data:', result.data);
            console.log('FileName:', result.file_name);
          }
        });
        // if (isMounted) {
        //   // Debug: Log the received result
        //   console.log('Fetched file result:', result);
          
        //   // Set data, columns, and fileName if available
        //   setColumns(result.columns || []);
        //   setData(result.data || []);
        //   setFileName(result.file_name || '');

        //   // Debug: Log the state values after setting
        //   console.log('Columns:', result.columns);
        //   console.log('Data:', result.data);
        //   console.log('FileName:', result.file_name);
        // }
      } catch (error) {
        if (isMounted) {
          setError(error.message);
          console.error('Error fetching file:', error);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [prefix, refresh]);

  return { data, columns, fileName, loading, error };
};
