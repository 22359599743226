import { LdsHeader } from '@elilillyco/ux-lds-react';
import '@elilillyco/ux-lds-react/src/css/index.css';
import logo from '../custom/img/image.png';
import '../custom/styles/Header.css';

const Header = ({ initLoad,notFound,accessDenied, files, cookieFile, setCookieFile, setuserName, userName }) => {
  const headerConfig = [
    { itemText: 'Home', href: '/home'},
    { itemText: 'Answer Pattern', href: '/answerpattern' },
    { itemText: 'Risk Indicators', href: '/riskindicators' },
  ];

  return (
    initLoad || (notFound || accessDenied) ? 
    <LdsHeader className="navbar-container" lillyLogo={{
      size: 'md',
      className: 'pl-0 pr-0',
      link: '/home',
    }}>
    </LdsHeader> :


    // <LdsHeader
    // menuItems={headerConfig}
    // customLogo={
    // <div className="logo-section">
    //   <img src={logo} alt="Logo" className="logo" />
    //   <span className="logo-text">WwTP Commodity Code</span>
    // </div>}
    // ></LdsHeader>

    <LdsHeader className="header" menuItems={headerConfig} lillyLogo={{
      size: 'md',
      className: 'pl-0 pr-0',
      link: '/home',
      }}>
    </LdsHeader>
  )
  
}

export default Header;