import React from 'react';
import { LdsTable} from '@elilillyco/ux-lds-react';
import '../../custom/styles/DataTable.css';

const DataTable = ({ columns, data }) => {
  return (
    <div className="">
      <LdsTable striped id="table-standard" role="table" className="data-table">
      <thead>
          <tr>
            {columns.map((column) => (
              <th key={column}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <td key={colIndex}>{row[column] || 'N/A'}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="no-data">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </LdsTable>
    </div>
  );
};

export default DataTable;