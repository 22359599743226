import { useState, useEffect } from 'react';
import { useUniqueValues } from './useUniqueValues';

export const useDataFilter = () => {
  const { uniqueCountries, uniqueAreaCodes, loading: uniqueLoading, error: uniqueError } = useUniqueValues();
  const [filters, setFilters] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const [riskLevelFilters, setRiskLevelFilters] = useState({
    'AC Risk Level': '',
    'Privacy Risk Level': '',
    'IS Risk Level': '',
    'AW Risk Level': '',
    'BC Risk Level': ''
  });

  // Initialize filters once unique values are available
  useEffect(() => {
    setFilters([
      { name: 'Engagement Service Location', label: 'Engagement Service Location', options: uniqueCountries, selectedValue: '' },
      { name: 'Commodity Codes', label: 'Commodity Code', options: uniqueAreaCodes, selectedValue: '' }
    ]);
  }, [uniqueCountries, uniqueAreaCodes]);

  // Apply filters whenever data or filters change
  useEffect(() => {
    applyFilters(filters, riskLevelFilters);
  }, [data, filters, riskLevelFilters]);

  // Handler for filter changes
  const handleFilterChange = (filterName, value) => {
    const updatedFilters = filters.map(filter =>
      filter.name === filterName ? { ...filter, selectedValue: value } : filter
    );
    setFilters(updatedFilters);
  };

  // Handler for risk level filter changes
  const handleRiskLevelChange = (riskColumn, value) => {
    setRiskLevelFilters(prevFilters => ({
      ...prevFilters,
      [riskColumn]: value
    }));
  };

  // Apply filters to data
  const applyFilters = (currentFilters, currentRiskLevelFilters) => {
    setIsFiltering(true); // Set filtering state to true
    let filtered = data;

    currentFilters.forEach(filter => {
      if (filter.selectedValue) {
        filtered = filtered.filter(row =>
          row[filter.name]?.toString().toLowerCase().includes(filter.selectedValue.toLowerCase())
        );
      }
    });


    // Apply risk level filters
    Object.keys(currentRiskLevelFilters).forEach(riskLevelKey => {
      const riskValue = currentRiskLevelFilters[riskLevelKey];
      if (riskValue) {
        filtered = filtered.filter(row =>
          row[riskLevelKey]?.toString() === riskValue
        );
      }
    });

    setFilteredData(filtered);
    setIsFiltering(false); // Set filtering state to false
  };

  // Clear selections for a specific filter
  const clearSelections = (filterName) => {
    handleFilterChange(filterName, '');
  };

  return { 
    filters, 
    filteredData, 
    handleFilterChange,
    handleRiskLevelChange,
    clearSelections, 
    setData, 
    uniqueLoading, 
    uniqueError, 
    isFiltering,
    riskLevelFilters
  };
};