// const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

import Utils from "../Common/Utils";

const env = Utils.getEnvVars();
const API_URL = env.FAST_API_URL || 'http://localhost:8000';

// Helper function to handle API requests
const fetchData = async (url, params = {}) => {
    try {
        const fullUrl = new URL(url, API_URL); // Ensure full URL construction
        Object.keys(params).forEach(key => fullUrl.searchParams.append(key, params[key]));

        const response = await fetch(fullUrl.toString());

        // Check if the response is valid JSON
        const text = await response.text();
        try {
            const json = JSON.parse(text);
            return json;
        } catch (e) {
            throw new Error(`Expected JSON, received: ${text}`);
        }

    } catch (error) {
        console.error('API Request Error:', error);
        throw error;
    }
};

const fetchFileWrap = async (fileName = null, prefix = 'data/', refresh = false,chunkSize=500,currentOffset=0,iteration=0,fileResult,cb) => {
    // Debug: Log parameters
    console.log(`Fetching file with fileName: ${fileName}, prefix: ${prefix}, refresh: ${refresh}`);

    const params = { file_name: fileName, prefix, refresh, chunk_size: chunkSize, current_offset: currentOffset,iteration:iteration };
    try {
        const result = await fetchData(`${API_URL}/fetch-file`, params);
        console.log('Fetch file result:', result); // Debug: Log the result
        result.data = result.data.split("<<>>")
        // result.data = JSON.parse(result.data)
        result.data = result.data.map(line => {
            try { 
                return JSON.parse(line);
            } catch (error) {
                console.error('Invalid JSON:', line, error);
                return null; // Return null for invalid lines or handle as needed
            }
        });
        if(Object.keys(fileResult).length < 1) {
            fileResult = {'data':[]}
        }
        fileResult.data = [...fileResult.data,...result.data];
        if(iteration === 0) fileResult.columns = result.columns;        
        fileResult.file_name = result.file_name;   
        if (result.data.length === chunkSize) {
            iteration = iteration + 1;
            await fetchFileWrap(fileName, prefix, refresh,chunkSize,result.next_offset,iteration,fileResult,cb);
        } else {
            if(cb) cb(fileResult)
        //     return fileResult;
        }
        // return result;
    } catch (error) {
        console.error('Error fetching file:', error);
        throw error;
    }
};

export const fetchFile = async (fileName = null, prefix = 'data/', refresh = false,chunkSize=500,currentOffset=0,iteration=0,fileResult,cb) => {
    // Debug: Log parameters
    console.log(`Fetching file with fileName: ${fileName}, prefix: ${prefix}, refresh: ${refresh}`);
    // let fileResult1 = {};
    await fetchFileWrap(fileName, prefix, refresh,chunkSize,currentOffset,iteration,fileResult,cb);
    // const params = { file_name: fileName, prefix, refresh, chunk_size: chunkSize, current_offset: currentOffset,iteration:iteration };
    // try {
    //     const result = await fetchData(`${API_URL}/fetch-file`, params);
    //     console.log('Fetch file result:', result); // Debug: Log the result
    //     result.data = result.data.split("<<>>")
    //     // result.data = JSON.parse(result.data)
    //     result.data = result.data.map(line => {
    //         try { 
    //             return JSON.parse(line);
    //         } catch (error) {
    //             console.error('Invalid JSON:', line, error);
    //             return null; // Return null for invalid lines or handle as needed
    //         }
    //     });
    //     if(Object.keys(fileResult).length < 1) {
    //         fileResult = {'data':[]}
    //     }
    //     fileResult.data = [...fileResult.data,...result.data];
    //     if(iteration === 0) fileResult.columns = result.columns;        
    //     fileResult.file_name = result.file_name;   
    //     if (result.data.length === chunkSize) {
    //         iteration = iteration + 1;
    //         await fetchFile(fileName, prefix, refresh,chunkSize,result.next_offset,fileResult,iteration);
    //     } else {
    //         return fileResult;
    //     }
    //     // return result;
    // } catch (error) {
    //     console.error('Error fetching file:', error);
    //     throw error;
    // }
};


// Clear the cache for the latest file
export const clearLatestFileCache = async () => {
    try {
        const response = await fetch(`${API_URL}/clear-cache`, {
            method: 'POST'
        });
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Failed to clear cache: ${response.statusText} - ${errorText}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error clearing cache:', error);
        throw error;
    }
};

// Fetch the list of files from S3 with an optional prefix
export const getFilesFromS3 = async (prefix = 'data/') => {
    const params = { prefix };
    return await fetchData(`${API_URL}/files/`, params);
};

// Fetch unique values from the latest file
export const getUniqueValues = async () => {
    return await fetchData(`${API_URL}/unique-values/`);
};

// Fetch row count with optional filters
export const getRowCount = async (country = '', areaCode = '', riskLevel = '') => {
    const params = { country, area_code: areaCode, risk_level: riskLevel };
    return await fetchData(`${API_URL}/row-count/`, params);
};

// Fetch column data with optional filters
export const getColumnData = async (country = '', areaCode = '') => {
    const params = { country, area_code: areaCode };
    return await fetchData(`${API_URL}/column-data/`, params);
};

export const getAnswerPattern = async (country, areaCode, riskCategory, riskLevel) => {
    const params = { country, area_code: areaCode, risk_category: riskCategory, risk_level: riskLevel };
    return await fetchData(`${API_URL}/answer-pattern/`, params);
};