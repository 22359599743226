// components/Chart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,   
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';

// Register the necessary components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const Chart = ({ columnData, columnName, excludeValue = null }) => {
  // Create chart data based on the provided column data and column name
  const createBarChartData = () => {
    // Check if column data exists and contains data for the specified column name
    if (!columnData || !columnData[columnName] || Object.keys(columnData[columnName]).length === 0) {
      return { labels: [], datasets: [] };
    }

    let values = columnData[columnName] || {};

    if (excludeValue) {
      values = Object.keys(values)
        .filter(key => key !== excludeValue)
        .reduce((acc, key) => {
          acc[key] = values[key];
          return acc;
        }, {});
    }

    return {
      labels: Object.keys(values),
      datasets: [
        {
          label: `Distribution of ${columnName}`,
          data: Object.values(values),
          backgroundColor: 'rgba(0, 51, 153, 0.7)',
          borderColor: 'rgba(0, 0, 0, 1)',
          hoverBackgroundColor: 'rgba(42, 172, 50, 0.7)',
          hoverBorderColor: 'rgba(0, 0, 0, 1)',
          borderWidth: 2,
        },
      ],
    };
  };

  // Chart options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category', // Specify the type of scale
        ticks: {
          maxRotation: 45,
          minRotation: 0,
          autoSkip: false,
          callback: function (value) {
            let label = this.getLabelForValue(value);
            return label.length > 10 ? label.substr(0, 10) + '...' : label;
          },
          font: {
            size: 15,
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Count',
          font: {
            size: 15,
            weight: 'bold',
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `Count: ${tooltipItem.raw}`,
        },
      },
    },
  };

  // Check if there is data to render
  const chartData = createBarChartData();
  const hasData = chartData.labels.length > 0;

  return (
    <div className="chart-container" style={{ height: '350px', marginBottom: '50px' }}>
      {hasData ? (
        <Bar data={chartData} options={chartOptions} />
      ) : (
        <p>No data available for the selected filters.</p>
      )}
    </div>
  );
};

export default Chart;
