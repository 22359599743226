import { useEffect, useState } from 'react';
import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";
import AccessDenied from './AccessDenied';
import AuthCallback from './AuthCallback';

const BodyWrap = ({ initLoad, notFound, accessDenied, isloading, setValidUser, setUserCheck, isValidUser,callback, setinitLoading, initloading,validPersona,setValidPersona, setpersonaAccess, personaAccess, setuserName, userName,subcontenttab }) => {
    const [cookieFile, setCookieFile] = useState([]);        
    return (
        <div>
            <Header initLoad={initLoad} notFound={notFound} accessDenied={accessDenied} isloading={isloading} setuserName={setuserName} userName={userName}/>
            {accessDenied ? 
                <AccessDenied /> : 
                isloading ? 
                    <AuthCallback setValidUser={setValidUser} setUserCheck={setUserCheck}  isValidUser={isValidUser} callback={callback} setinitLoading={setinitLoading} initloading={initloading}  setValidPersona={setValidPersona} validPersona={validPersona}  setpersonaAccess={setpersonaAccess} personaAccess={personaAccess} setuserName={setuserName} userName={userName}/> :
                    <Content initLoad={initLoad} notFound={notFound} cookieFile={cookieFile} setCookieFile={setCookieFile} initloading={initloading} setValidPersona={setValidPersona} validPersona={validPersona} setpersonaAccess={setpersonaAccess} personaAccess={personaAccess} subcontenttab={subcontenttab}/> 
            }
            <Footer initLoad={initLoad} notFound={notFound} />
        </div>
    )
}

export default BodyWrap;