import React from 'react';
import '../custom/styles/Risk.css';

function Risk() {
    return (
      <div className="coming-soon-container">
      <div className="coming-soon-content">
          <h1>Coming Soon</h1>
      </div>
  </div>
    );
  }
export default Risk;
