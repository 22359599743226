import React from 'react';
import '../../custom/styles/SelectDrop.css';

const SelectDrop = ({ label, options, selectedValue, onChange, clearSelection }) => (
  <div className="select-container">
    <label className="label">{label}</label>
    <div className="select-wrapper">
      <select className="select-input" value={selectedValue} onChange={onChange}>
        <option value="">Select...</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      {selectedValue && (
        <button className="clear-select" onClick={clearSelection} aria-label={`Clear ${label} selection`}>&times;</button>
      )}
    </div>
  </div>
);

export default React.memo(SelectDrop);