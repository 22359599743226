// hooks/useColumnData.js
import { useState, useEffect } from 'react';
import { getColumnData } from '../utils/api'; // Assuming this is the API call to your backend

export const useColumnData = (country, areaCode) => {
  const [columnData, setColumnData] = useState({});
  const [modes, setModes] = useState({}); // Add state to store mode values
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchColumnData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch column data based on the filters
        const data = await getColumnData(country, areaCode); // Pass country and areaCode separately

        if (isMounted) {
          setColumnData(data);

          // Extract and set the mode values
          setModes({
            CI: data.ci_mode,
            PI: data.privacy_mode,
          });
        }
      } catch (error) {
        if (isMounted) {
          setError(error.message);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchColumnData();

    return () => {
      isMounted = false; // Cleanup on component unmount
    };
  }, [country, areaCode]); // Refetch when country or areaCode changes

  return { columnData, modes, loading, error }; // Return modes along with columnData
};