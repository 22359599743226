import { useState, useEffect } from 'react';
import { getRowCount } from '../utils/api';

export const useRowCount = (country, areaCode, riskLevel) => {
  const [rowCount, setRowCount] = useState(0);
  const [rowCountLevel1, setRowCountLevel1] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    async function fetchRowCounts() {
      try {
        setLoading(true);
        setError(null);

        // Fetch total row count from the backend with riskLevel
        const totalCount = await getRowCount(country, areaCode, riskLevel);
        // Fetch total count specifically for Level 1 (Limited) without altering its existing logic
        const totalCountLevel1 = await getRowCount(country, areaCode, 'Level 1 (Limited)');

        if (isMounted) {
          setRowCount(totalCount.total_count);
          setRowCountLevel1(totalCountLevel1.total_count);
        }
      } catch (error) {
        if (isMounted) {
          setError('Error fetching row counts');
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    // Ensure we fetch row counts if any relevant state changes
    fetchRowCounts();

    return () => {
      isMounted = false; // Cleanup to prevent state updates if component unmounts
    };
  }, [country, areaCode, riskLevel]); // Add riskLevel to dependency array

  return { rowCount, rowCountLevel1, loading, error };
};